import router from './router'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle } from '@/utils/domUtil'

const allowList = ['login', 'register', 'index', 'about', 'map', 'support'] // no redirect allowList
const loginRoutePath = '/user/login'
const defaultRoutePath = '/home'

NProgress.configure({ showSpinner: true }) // NProgress Configuration

router.beforeEach((to, from, next) => {
    NProgress.start() // start progress bar
    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - 巴蜀云课程`))
    if(storage.get(ACCESS_TOKEN)) {
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath })
            NProgress.done()
        } else {
            next()
        }
    } else {
        if(allowList.includes(to.name)) {
            next()
        } else {
            const redirect = decodeURIComponent(to.fullPath)
            next({ path: loginRoutePath, query: { redirect: redirect} })
            NProgress.done()
        }
    }
    next()
})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
