var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-nav"},[_c('div',{staticClass:"web-nav-top"},[_c('div',{staticClass:"web-nav-top-content"},[_vm._m(0),_c('div',{staticClass:"nav-right"},[_c('ul',{staticClass:"login-actions"},[(!_vm.islogin)?_c('li',{on:{"click":_vm.loginAction}},[_vm._v("【登录】")]):_vm._e(),(!_vm.islogin)?_c('li',{on:{"click":_vm.registerAction}},[_vm._v("【注册】")]):_vm._e(),_c('li',{on:{"click":_vm.userCenterAction}},[_vm._v("【个人中心】")])]),_vm._m(1)])])]),_c('div',{staticClass:"web-nav-menu"},[_c('div',{staticClass:"web-nav-menu-content"},[_c('ul',{staticClass:"web-nav-menus"},[_c('router-link',{attrs:{"to":"/index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"web-nav-menu-item",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ],attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',[_vm._v("首页")])])]}}])}),_c('router-link',{attrs:{"to":"/map"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"web-nav-menu-item",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ],attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',[_vm._v("展馆介绍")])])]}}])}),_vm._m(2),_c('router-link',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"web-nav-menu-item",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ],attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',[_vm._v("关于我们")])])]}}])}),_c('router-link',{attrs:{"to":"/support"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"web-nav-menu-item",class:[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active' ],attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('span',[_vm._v("使用帮助")])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-warp"},[_c('img',{attrs:{"src":require("@/assets/wlogo.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("云巴蜀课程博览馆")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"seach-side"},[_c('p',{staticClass:"welcome"},[_vm._v("您好，欢迎来到云巴蜀！")]),_c('div',{staticClass:"search-warp"},[_c('input',{staticClass:"inp",attrs:{"placeholder":"搜索","type":"text"}}),_c('div',{staticClass:"search"},[_c('img',{attrs:{"src":require("@/assets/wsearch.png"),"alt":"","srcset":""}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('li',{staticClass:"web-nav-menu-item"},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"http://s.cxxxw.cn"}},[_vm._v("资源库")])])])}]

export { render, staticRenderFns }