<template>
  <div class="smd-menu">
    <img class="logo" @click="logoAction" src="../assets/logo.png" />
    <ul class="smd-menu-list">
      <router-link
        v-slot="{ href, navigate, isActive }"
        v-for="item in menulist"
        :key="item.icon"
        class="smd-menu-item"
        @click="menuClick(item.path)"
        :to="item.path"
      >
        <li
          :active="isActive"
          :href="href"
          @click="navigate"
          class="smd-menu-item"
        >
          <a-tooltip placement="right">
            <template slot="title"> {{ item.name }}</template>
            <img
              class="icon"
              :src="isActive ? item.selectIcon : item.icon"
              alt=""
            />
            <img
              class="active"
              v-if="isActive"
              src="../assets/active.png"
              alt=""
              srcset=""
            />
          </a-tooltip>
        </li>
      </router-link>

      <!-- <li
          :active="isActive"
          :href="href"
          @click="navigate"
          class="smd-menu-item"
        >
          <a-tooltip placement="right">
            <template slot="title"> {{ item.name }}</template>
            <img
              class="icon"
              :src="isActive ? item.selectIcon : item.icon"
              alt=""
            />
            <img
              class="active"
              v-if="isActive"
              src="../assets/active.png"
              alt=""
              srcset=""
            />
          </a-tooltip>
        </li> -->

      <a-popover title="" placement="right">
        <template slot="content">
          <div class="service-content">
            <img
              src="@/assets/avatar_sevicce.png"
              class="service-icon"
              alt=""
              srcset=""
            />
            <div class="service-c-r">
              <p class="tel">023-8888 8888</p>
              <p>联系学校，获取更多资源。</p>
            </div>
          </div>
        </template>
        <li class="smd-menu-item">
          <img class="icon" src="../assets/service.png" alt="" />
        </li>
      </a-popover>
    </ul>
    <a-popconfirm
      title="确定要退出登录吗？"
      placement="topLeft"
      ok-text="是的"
      cancel-text="不"
      @confirm="confirmExit"
    >
      <img class="exit" :style="menulist.length == 3 ? 'top:520Px;' : 'top: 609Px;'" src="../assets/exit.png" alt="" srcset="" />
    </a-popconfirm>
  </div>
</template>

<script>
import home from "../assets/home.png";
import homes from "../assets/home_s.png";
import shenhe from "../assets/shenhe.png";
import shenhes from "../assets/shenhe_s.png";
import collection from "../assets/collection.png";
import collections from "../assets/collection_s.png";
import book from "../assets/book.png";
import books from "../assets/book_s.png";
import frint from "../assets/print.png";
import frints from "../assets/print_s.png";
import service from "../assets/service.png";
import services from "../assets/service_s.png";
import newlesstion from "../assets/newlesstion.png";
import newlesstions from "../assets/newlesstion_s.png";
import { mapState } from "vuex";
// {
//   icon: frint,
//   selectIcon: frints,
//   path: "/print",
//   name: "我的足迹",
// },
//         {
//   icon: collection,
//   selectIcon: collections,
//   path: "/collection",
//   name: "我的收藏",
// },
// {
//           icon: service,
//           selectIcon: services,
//           path: "/service",
//           name: "客服",
//         },
export default {
  name: "smd-menu",
  data() {
    return {
      menus: [
        {
          icon: home,
          selectIcon: homes,
          path: "/home",
          name: "首页",
        },
        {
          icon: book,
          selectIcon: books,
          path: "/book",
          name: "我的课程",
        },
        {
          icon: newlesstion,
          selectIcon: newlesstions,
          path: "/newLession",
          name: "新增课程",
        },
        {
          icon: shenhe,
          selectIcon: shenhes,
          path: "/review",
          name: "审核管理",
        },
      ],
      currentPath: "/home",
    };
  },
  computed:{
    ...mapState(['user']),
    menulist(){
      return this.$store.state.user.teachGroup == -1 ? this.menus.slice(0,3) : this.menus;
    }
  },
  mounted() {
    this.currentPath = this.$route.path;
    if (this.currentPath.indexOf("/book") != -1) {
      this.currentPath = "/book";
    }
  },
  methods: {
    menuClick(path) {
      console.log(this.$route);
      if (path == this.currentPath) {
        return;
      }
      this.currentPath = path;
      this.$router.push(path);
    },
    confirmExit() {
      this.$store.commit("M_LOGOUT");
      this.$router.push({ name: "login" });
    },
    logoAction() {
      this.$router.push({ name: "index" });
    },
  },
};
</script>

<style lang='less' >
.smd-menu {
  width: 100Px;
  text-align: center;
  .logo {
    width: 70Px;
    height: 68Px;
    margin-top: 27Px;
    margin-bottom: 50Px;
    cursor: pointer;
  }
  .smd-menu-list {
    padding: 0;
    width: 100Px;
    .smd-menu-item {
      cursor: pointer;
      display: block;
      position: relative;
      padding: 30Px 0;
      .icon {
        width: 28Px;
        height: 28Px;
      }
      .active {
        position: absolute;
        right: -1Px;
        top: -8Px;
        width: 32Px;
        height: 104Px;
        cursor: default;
      }
    }
  }
  .exit {
    width: 28Px;
    height: 28Px;
    position: absolute;
    // top: 460Px;
    top: 609Px;
    left: 38Px;
    cursor: pointer;
  }
}
</style>