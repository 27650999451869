import axios from 'axios'
import storage from 'store'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

//创建 axios 实例
const request = axios.create({
  // api 请求默认前置
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL:'/api',
  timeout: 15000 //请求超时时间
})

const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  return Promise.reject(error)
}

//请求拦截器
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN);
  if (token) {
    config.headers['au'] = token
  }
  return config;
}, errorHandler)

//响应拦截器
request.interceptors.response.use((response) => {
  if (response.data.code != 200) {
    notification.warning({
      message: '系统提示',
      description: response.data.msg
    })
    if(response.data.code == 401) {
      store.commit('M_LOGOUT')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request;

export {
  installer as VueAxios,
  request as axios
}