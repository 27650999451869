<template>
  <div class="smd-basic-ayout">
      <smd-menu></smd-menu>
      <div class="layout-right">
          <keep-alive>
              <router-view />
          </keep-alive>
      </div>
  </div>
</template>

<script>

import SMDMenu from '../components/Menu.vue'
export default {
    components: {
        'smd-menu': SMDMenu
    }
}
</script>

<style lang='less'>
.smd-basic-ayout {
    background: @primary-color;
    display: flex;
    min-height: 100vmin;
    .layout-right {
        width: calc(100% - 100Px);
        background: #EDF0F5;
        border-top-left-radius: 40Px;
        border-bottom-left-radius: 40Px;
        padding-top: 40Px;
        padding-left: 40Px;
        min-height: 800Px;
    }
}
</style>