import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user
    },
    state: {
        bookRouters:[],
        name: ''
    },
    mutations: {
        M_ADD_BOOK_ROUTER(state, payload) {
            console.log('payload', payload)
            state.bookRouters = payload
        }
    },
    actions: {
        
    },
    getters: {
        
    }
})