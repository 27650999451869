<template>
  <div class="web-nav">
    <div class="web-nav-top">
      <div class="web-nav-top-content">
        <div class="logo-warp">
          <img src="@/assets/wlogo.png" alt="" srcset="" />
          <span>云巴蜀课程博览馆</span>
        </div>
        <div class="nav-right">
          <ul class="login-actions">
            <li @click="loginAction" v-if="!islogin">【登录】</li>
            <li @click="registerAction" v-if="!islogin">【注册】</li>
            <li @click="userCenterAction">【个人中心】</li>
          </ul>
          <div class="seach-side">
            <p class="welcome">您好，欢迎来到云巴蜀！</p>
            <div class="search-warp">
              <input class="inp" placeholder="搜索" type="text" />
              <div class="search">
                <img src="@/assets/wsearch.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="web-nav-menu">
      <div class="web-nav-menu-content">
        <ul class="web-nav-menus">
          <router-link
            to="/index"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              :active="isActive"
              :href="href"
              @click="navigate"
              class="web-nav-menu-item"
            >
              <span>首页</span>
            </li>
          </router-link>
          <router-link
            to="/map"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              :active="isActive"
              :href="href"
              @click="navigate"
              class="web-nav-menu-item"
            >
              <span>展馆介绍</span>
            </li>
          </router-link>
          <div>
            <li class="web-nav-menu-item">
              <a href="http://s.cxxxw.cn" style="color: white">资源库</a>
            </li>
          </div>
          <router-link
            to="/about"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              :active="isActive"
              :href="href"
              @click="navigate"
              class="web-nav-menu-item"
            >
              <span>关于我们</span>
            </li>
          </router-link>
          <router-link
            to="/support"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              :active="isActive"
              :href="href"
              @click="navigate"
              class="web-nav-menu-item"
            >
              <span>使用帮助</span>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-nav",
  computed: {
    islogin() {
      console.log(this.$store);
      return this.$store.state.user.token &&
        this.$store.state.user.token.length >= 0
        ? true
        : false;
    },
  },
  methods: {
    loginAction() {
      this.$router.push({ name: "login" });
    },
    registerAction() {
      this.$router.push({ name: "register" });
    },
    userCenterAction() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="less">
.web-nav {
  height: 112px;
  max-width: 1920px;
  margin: 0 auto;
  .web-nav-top {
    height: 74px;
    .web-nav-top-content {
      width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .logo-warp {
        padding-left: 70px;
        padding-top: 17px;
        img {
          width: 44px;
          height: 44px;
        }
        span {
          font-size: 16px;
          font-family: Segoe UI;
          font-weight: 600;
          line-height: 21px;
          color: #230a02;
          padding-left: 4px;
        }
      }
      .nav-right {
        display: flex;
        .seach-side {
          padding-top: 10px;
          font-size: 12px;
          .welcome {
            text-align: right;
          }
          .search-warp {
            height: 18px;
            width: 165px;
            display: flex;
            .inp {
              height: 18px;
              border: 1px solid #d9d9d9;
              border-right: none;
              outline: none;
              width: 138px;
              padding-left: 10px;
              font-size: 12px;
            }
            .search {
              width: 25px;
              height: 18px;
              background: #801212;
              text-align: center;
            }
          }
        }
        .login-actions {
          margin-right: 35px;
          padding-top: 38px;
          display: flex;
          li {
            padding: 0 5px;
            cursor: pointer;
            font-size: 14px;
            color: #474544;
          }
        }
      }
    }
  }
  .web-nav-menu {
    height: 38px;
    background: #801212;
    .web-nav-menu-content {
      width: 1170px;
      margin: 0 auto;
      .web-nav-menus {
        height: 100%;
        margin: 0;
        padding: 0;
        height: 38px;
        display: flex;
        align-items: center;
        .web-nav-menu-item {
          color: white;
          margin-left: 47px;
          cursor: pointer;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 8px;
        }
      }
    }
  }
}
.router-link-active,
.router-link-exact-active {
  background: #801212;
}
</style>