import {
    UserLayout,
    BasicLayout,
    WebsiteLayout
} from '@/layouts'

export const asyncRouterMap = [{
        path: '/center',
        name: 'userhome',
        component: BasicLayout,
        redirect: '/home',
        meta: {
            title: '云巴蜀课程博览馆',
            keepAlive: true
        },
        children: [{
                path: '/home',
                name: 'home',
                meta: {
                    title: '首页',
                    keepAlive: true
                },
                component: () => import('@/views/home/Home'),
            },
            {
                path: '/chart',
                name: 'chart',
                meta: {
                    title: '统计',
                    keepAlive: true
                },
                component: () => import('@/views/home/Chart'),
            },
            {
                path: '/collection',
                name: 'collection',
                meta: {
                    title: '首页',
                    keepAlive: true
                },
                component: () => import('@/views/collection/Collection'),
            },
            {
                path: '/book',
                name: 'book',
                meta: {
                    title: '工具',
                    keepAlive: true
                },
                component: () => import('@/views/book/Book'),
                redirect: '/book/course',
                children: [{
                        path: '/book/course',
                        name: 'course',
                        meta: {
                            title: '我的课程'
                        },
                        component: () => import('@/views/book/Course'),
                    },
                    {
                        path: '/book/course/detail',
                        name: 'courseDetailRoot',
                        meta: {
                            title: '课程详情'
                        },
                        component: () => import('@/views/book/course-detail/index'),
                        redirect: '/book/course/detail',
                        children: [{
                                path: '/book/course/detail',
                                name: 'courseDetail',
                                meta: {
                                    title: '课程详情'
                                },
                                component: () => import('@/views/book/course-detail/detail'),
                            },
                            {
                                path: '/book/course/unitdetail',
                                name: 'unitDetail',
                                meta: {
                                    title: '单元详情'
                                },
                                component: () => import('@/views/book/course-detail/UnitDetail'),
                                redirect: '/book/course/unitinterpretation',
                                children: [{
                                        path: '/book/course/unitinterpretation',
                                        name: 'unitInterpretation',
                                        meta: {
                                            title: '单元解读'
                                        },
                                        component: () => import('@/views/book/course-detail/unit-detail/UnitInterpretation'),
                                    },
                                    {
                                        path: '/book/course/lessondesign',
                                        name: 'lessonDesign',
                                        meta: {
                                            title: '单科设计'
                                        },
                                        component: () => import('@/views/book/course-detail/unit-detail/LessonDesign'),
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: '/review',
                name: 'review',
                meta: {
                    title: '审核',
                    keepAlive: true
                },
                component: () => import('@/views/review/Review'),
                redirect: '/review/course',
                children: [{
                    path: '/review/course',
                    name: 'reviewCourse',
                    meta: {
                        title: '审核管理'
                    },
                    component: () => import('@/views/review/Course'),
                }, {
                    path: '/review/course/partlist',
                    name: 'partlist',
                    meta: {
                        title: '课程详情'
                    },
                    component: () => import('@/views/review/review-detail/index'),
                    redirect: '/review/course/detail',
                    children: [{
                            path: '/review/course/detail',
                            name: 'reviewDetail',
                            meta: {
                                title: '课程详情',
                                review: 'true',
                                keepAlive: true
                            },
                            component: () => import('@/views/review/review-detail/PartList'),
                        },
                        {
                            path: '/review/course/unitdetail',
                            name: 'reviewUnitDetail',
                            meta: {
                                title: '单元详情',
                                review: 'true',
                                keepAlive: false
                            },
                            component: () => import('@/views/review/review-detail/UnitDetail'),
                            redirect: '/review/course/reviewUnitInterpretation',
                            children: [{
                                    path: '/review/course/reviewUnitInterpretation',
                                    name: 'reviewUnitInterpretation',
                                    meta: {
                                        title: '单元解读',
                                        review: 'true',
                                        keepAlive: false
                                    },
                                    component: () => import('@/views/review/review-detail/unit-detail/UnitInterpretation'),
                                },
                                {
                                    path: '/review/course/reviewLessonDesign',
                                    name: 'reviewLessonDesign',
                                    meta: {
                                        title: '单科设计',
                                        review: 'true',
                                        keepAlive: false
                                    },
                                    component: () => import('@/views/review/review-detail/unit-detail/LessonDesign'),
                                }
                            ]
                        }
                    ]
                }]
            },
            {
                path: '/newLession',
                name: 'newLession',
                meta: {
                    title: '新增',
                    keepAlive: true
                },
                component: () => import('@/views/newLession/Book'),
                redirect: '/newLession/course',
                children: [{
                        path: '/newLession/course',
                        name: 'newcourse',
                        meta: {
                            title: '我的课程'
                        },
                        component: () => import('@/views/newLession/Course'),
                    },
                    {
                        path: '/newLession/course/detail',
                        name: 'newcourseDetailRoot',
                        meta: {
                            title: '课程详情',
                            keepAlive: true
                        },
                        component: () => import('@/views/newLession/course-detail/index'),
                        redirect: '/newLession/course/detail',
                        children: [{
                                path: '/newLession/course/detail',
                                name: 'newcourseDetail',
                                meta: {
                                    title: '课程详情',
                                    keepAlive: true
                                },
                                component: () => import('@/views/newLession/course-detail/detail'),
                            },
                            {
                                path: '/newLession/course/unitdetail',
                                name: 'newunitDetail',
                                meta: {
                                    title: '单元详情',
                                    keepAlive: false
                                },
                                component: () => import('@/views/newLession/course-detail/UnitDetail'),
                                redirect: '/newLession/course/unitinterpretation',
                                children: [{
                                        path: '/newLession/course/unitinterpretation',
                                        name: 'newunitInterpretation',
                                        meta: {
                                            title: '单元解读',
                                            keepAlive: false
                                        },
                                        component: () => import('@/views/newLession/course-detail/unit-detail/UnitInterpretation'),
                                    },
                                    {
                                        path: '/newLession/course/lessondesign',
                                        name: 'newlessonDesign',
                                        meta: {
                                            title: '单科设计',
                                            keepAlive: false
                                        },
                                        component: () => import('@/views/newLession/course-detail/unit-detail/LessonDesign'),
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: '/print',
                name: 'print',
                meta: {
                    title: '足迹',
                    keepAlive: true
                },
                component: () => import('@/views/print/Print'),
            },
            {
                path: '/service',
                name: 'service',
                meta: {
                    title: '客服',
                    keepAlive: true
                },
                component: () => import('@/views/service/Service'),
            }
        ]
    },
    {
        path: '/',
        name: 'index',
        meta: {
            title: "首页"
        },
        component: WebsiteLayout,
        redirect: '/index',
        children: [{
                path: '/index',
                name: "index",
                component: () => import('@/views/website/Index'),
            },
            {
                path: '/map/hall',
                name: "hall",
                component: () => import('@/views/website/Hall'),
            },
            {
                path: '/map/hall/detail',
                name: "hallDetail",
                component: () => import('@/views/website/Detail'),
            },
            {
                path: '/map',
                name: "map",
                component: () => import('@/views/website/Map'),
            },
            {
                path: '/about',
                name: "about",
                component: () => import('@/views/website/About'),
            },
            {
                path: '/support',
                name: "support",
                component: () => import('@/views/website/Support'),
            }
        ]
    }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
        path: '/user',
        component: UserLayout,
        redirect: '/user/login',
        hidden: true,
        meta: {
            title: '登录'
        },
        children: [{
                path: 'login',
                name: 'login',
                component: () => import( /* webpackChunkName: "user" */ '@/views/user/Login'),
                meta: {
                    title: '登录'
                },
            },
            {
                path: 'register',
                name: 'register',
                component: () => import( /* webpackChunkName: "user" */ '@/views/user/Register'),
                meta: {
                    title: '注册'
                },
            }
        ]
    },
    {
        path: '/404',
        component: () => import( /* webpackChunkName: "fail" */ '@/views/exception/404')
    }
]