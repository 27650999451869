<template>
  <div class="smd-user-layout">
      <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.smd-user-layout {
    background: #EFF7FF;
    height: 100%;
    width: 100%;
}
</style>