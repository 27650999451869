import { login } from '@/api/user'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import notification from 'ant-design-vue/es/notification'

const user = {
    state: {
        token: '',
        name: '',
        teachGroup: -1 
    },
    mutations: {
        M_SET_TOKEN: (state, token) => {
            state.token = token
        },
        M_SET_TeachGroup: (state, teach) => {
            state.teachGroup = teach
        },
        M_LOGOUT(state) {
            storage.remove(ACCESS_TOKEN)
            state.token = ""
            state.teachGroup = -1
        }
    },
    actions: {
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(res => {
                    if(res.code == 200) {
                        storage.set(ACCESS_TOKEN, res.data.token)
                        storage.set('ACCESS_TeachGroup', res.teachGroup)
                        commit('M_SET_TOKEN', res.data.token)
                        commit('M_SET_TeachGroup', res.teachGroup)
                        resolve()
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}


export default user