import request from '../utils/request'

const apis = {
    Login: '/api/MyLesson/Login', //登录
    RegTeacher: '/api/MyLesson/RegTeacher',
    MyLesson: '/api/MyLesson/GetMyAllLessonHall',
    CourseInfo: '/api/MyLesson/GetMyLessonLstByCourse', //我的某一科（语文）的年级、上下册信息
    UnitInfo: '/api/MyLesson/GetUnitInfoById',
    SectionInfo: '/api/MyLesson/GetSectionById',
    UpdateMySection: '/api/MyLesson/UpdateMySection',
    CreateMyAction: '/api/MyLesson/CreateMyAction',
    DelMyAction: '/api/MyLesson/DelMyAction',
    DelReSectionApx: '/api/MyLesson/DelReSectionApx',
    DelReLessonApx: '/api/MyLesson/DelReLessonApx',
    UpdataActionInfo: '/api/MyLesson/UpdataActionInfo',
    ActionSort: '/api/MyLesson/ActionSort',
    AddLessonApx: '/api/MyLesson/AddLessonApx',
    AddSectionApx: '/api/MyLesson/AddSectionApx',
    MissingSectionApxList: '/api/MyLesson/MissingSectionApxList',
    MissingLessonApxList: '/api/MyLesson/MissingLessonApxList',
    DelAliYunFile: '/api/MyLesson/DelAliYunFile',
    RemoveApx: '/api/MyLesson/RemoveApx',
    GetBsIdeas: '/api/MyLesson/GetBsIdeas',
    MissingActionList: '/api/MyLesson/MissingActionList',
    ReAction: '/api/MyLesson/ReAction',
    RefreshMyUnit: '/api/MyLesson/RefreshMyUnit',
    RefreshMyLesson: '/api/MyLesson/RefreshMyLesson',
    DelMyUnit: '/api/MyLesson/DelMyUnit',
    DelMyLesson: '/api/MyLesson/DelMyLesson',
}

/**
 * 教师登录
 * @param {*
 *  tname:'',
 *  tpwd:''
 * } parameter 
 */
export function login(parameter) {
    return request({
        url: apis.Login,
        method: 'post',
        data: parameter
    })
}

/**
 * 教师注册
 * @param {{"name":"test1001","phone":"13112345678","pwd":"123456","school":"重庆天宝小学","teach":"语文,数学","province":"重庆","city":"重庆市","area":"九龙坡区"}} parameter 
 */
export function register(parameter) {
    return request({
        url: apis.RegTeacher,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取我的课程
 * @param {*} parameter 
 */
export function getMyLesson(parameter) {
    return request({
        url: apis.MyLesson,
        method: 'post',
        data: parameter
    })
}

/**
 * 依据科目获取我的课程
 * @param {course:'语文'} params 
 */
export function getCourseInfoByCourseName(parameter) {
    return request({
        url: apis.CourseInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 依据单元ID获取课程课时信息
 * @param {unitId:'55'} params 
 */
export function getUnitInfoById(parameter) {
    return request({
        url: apis.UnitInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 刷新单元
 * @param {unitId:'55'} params 
 */
export function refreshMyUnit(parameter) {
    return request({
        url: apis.RefreshMyUnit,
        method: 'post',
        data: parameter
    })
}

/**
 * 刷新课时
 * @param {lessonId:'55'} params 
 */
export function refreshMyLesson(parameter) {
    return request({
        url: apis.RefreshMyLesson,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除单元
 * @param {unitId:'55'} params 
 */
export function deleteMyUnit(parameter) {
    return request({
        url: apis.DelMyUnit,
        method: 'post',
        data: parameter
    })
}

/**
 * 删除课时
 * @param {lessonId:'55'} params 
 */
export function deleteMyLesson(parameter) {
    return request({
        url: apis.DelMyLesson,
        method: 'post',
        data: parameter
    })
}



/**
 * 依据ID获取课时信息
 * @param {sectionId:'55'} parameter 
 */
export function getSectionById(parameter) {
    return request({
        url: apis.SectionInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 更新课时目标
 * @param {sectionId:'55', target:'新得目标'} parameter 
 */
export function updateMySection(parameter) {
    return request({
        url: apis.UpdateMySection,
        method: 'post',
        data: parameter
    })
}

/**
 * 在课中创建新活动
 * @param {sectionId:'55'} parameter 
 */
export function createMyAction(parameter) {
    return request({
        url: apis.CreateMyAction,
        method: 'post',
        data: parameter
    })
}

/**
 * 在课中删除活动
 * @param {actionId:'55'} parameter 
 */
export function delMyAction(parameter) {
    return request({
        url: apis.DelMyAction,
        method: 'post',
        data: parameter
    })
}



/**
 * 删除恢复附件 课前课中课后
 * @param {apxId:'55',type:模式 必须 [0删除 1恢复]} parameter 
 */
export function delReSectionApx(parameter) {
    return request({
        url: apis.DelReSectionApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 删除附件 课中模块附件
 * @param {apxId:'55',type:模式 必须 [0删除 1恢复]} parameter 
 */
export function delReLessonApx(parameter) {
    return request({
        url: apis.DelReLessonApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 新增附件 课中模块附件[上传OSS|链接]
 * @param {"actionId":214,"purpose":"网上找的","linkUrl":"http://www.smd.com/c/1.jpg"} parameter 
 */
export function addLessonApx(parameter) {
    return request({
        url: apis.AddLessonApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 新增附件 课中模块附件[上传OSS|链接]
 * @param {"sectionId":54,"appendixType":3,"purpose":"网上的资源","apxTag":"拓展资源","linkUrl":"http://www.smd.com/c/1.jpg"} parameter 
 * 附件信息 必须，appendixType [1课前|2课中|3课后]，purpose 教学用途，链接，两个结构二选一
 */
export function addSectionApx(parameter) {
    return request({
        url: apis.AddSectionApx,
        method: 'post',
        data: parameter
    })
}

/**
 * 更新模块信息[名称|目标|背景色]
 * @param {
 * actionId:'55',
 * newValue:'',
 * filed:修改字段 name 默认|target|bgColor
 * } parameter 
 */
export function updataActionInfo(parameter) {
    return request({
        url: apis.UpdataActionInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 课中模块排序
 * @param {
 * model:[{"aid":187,"sort":1},{"aid":188,"sort":2},{"aid":189,"sort":3},{"aid":190,"sort":4},{"aid":191,"sort":5}]
 * } parameter 
 */
export function actionSort(parameter) {
    return request({
        url: apis.ActionSort,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取被删除附件列表 课前课中课后
 * @param {sectionId:55,apxType:必须 1课前|2课中|3课后} parameter 
 */
export function missingSectionApxList(parameter) {
    return request({
        url: apis.MissingSectionApxList,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取被删除附件列表 课中模块附件
 * @param {actionId:8} parameter 
 */
export function missingLessonApxList(parameter) {
    return request({
        url: apis.MissingLessonApxList,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取被删除模块列表
 * @param {sectionId:8} parameter 
 */
export function missingActionList(parameter) {
    return request({
        url: apis.MissingActionList,
        method: 'post',
        data: parameter
    })
}


/**
 * 恢复删除模块
 * @param {actionId:8} parameter 
 */
export function reAction(parameter) {
    return request({
        url: apis.ReAction,
        method: 'post',
        data: parameter
    })
}


/**
 * 删除OSS上文件
 * @param {key:course/20201214/5313078824656884887.jpg} parameter 
 */
export function delAliYunFile(parameter) {
    return request({
        url: apis.DelAliYunFile,
        method: 'post',
        data: parameter
    })
}

/**
 * 物理删除附件
 * @param {apxId:4,type:附件所在区域 [1 section 默认|2 action]} parameter 
 */
export function removeApx(parameter) {
    return request({
        url: apis.RemoveApx,
        method: 'post',
        data: parameter
    })
}


/**
 * 获取巴蜀思路
 * @param {sectionId:51} parameter 
 */
export function getBsIdeas(parameter) {
    return request({
        url: apis.GetBsIdeas,
        method: 'post',
        data: parameter
    })
}