export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function timeFixs() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上' : hour <= 11 ? '上午' : hour <= 13 ? '中午' : hour < 20 ? '下午' : '晚上'
}

export function numFix(num) {
  if (num < 10) {
    return `0${num}`
  }
  return `${num}`
}


export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

import pdf from "@/assets/pdf.png"
import ppt from "@/assets/ppt.png"
import mp4 from "@/assets/video.png"
import doc from "@/assets/word.png"
import xls from "@/assets/xls.png"
import unknown from "@/assets/unknown.png"
import mp3 from "@/assets/mp3.png"
import pic from "@/assets/pic.png"
import zip from "@/assets/zip.png"

export function fileType(url) {
  var d = String(/.[^.]+$/.exec(url)).toLocaleLowerCase()
  switch (d) {
    case '.pdf':
      return pdf
      break;
    case '.ppt':
      return ppt
      break;
    case '.pptx':
      return ppt
      break;
    case '.mp4':
      return mp4
      break;
    case '.wmv':
      return unknown
      break;
    case '.m4a':
      return mp3
      break;
    case '.docx':
      return doc
      break;
    case '.doc':
      return doc
      break;
    case '.xls':
      return xls
      break;
    case '.xlsx':
      return xls
      break;
    case '.png':
      return pic
      break;
    case '.jpg':
      return pic
      break;
    case '.jpeg':
      return pic
      break;
    case '.gif':
      return pic
      break;
    case '.xlsx':
      return xls
      break;
    case '.zip':
      return zip
      break;
    case '.rar':
      return zip
      break;
    case '.mp3':
      return mp3
      break;
    case '.wav':
      return mp3
      break;
    case '.wma':
      return mp3
      break;
    case '.plac':
      return mp3
      break;
    case '.aac':
      return mp3
      break;
    default:
      return unknown
      break
  }
}

export function getFileType(url) {
  var d = String(/.[^.]+$/.exec(url)).toLocaleLowerCase()
  switch (d) {
    case '.pdf':
      return 'pdf'
      break;
    case '.ppt':
      return 'ppt'
      break;
    case '.pptx':
      return 'ppt'
      break;
    case '.mp4':
      return 'mp4'
      break;
    case '.wmv':
      return 'unknown'
      break;
    case '.m4a':
      return 'mp3'
      break;
    case '.docx':
      return 'doc'
      break;
    case '.doc':
      return 'doc'
      break;
    case '.xls':
      return 'xls'
      break;
    case '.xlsx':
      return 'xls'
      break;
    case '.png':
      return 'pic'
      break;
    case '.jpg':
      return 'pic'
      break;
    case '.jpeg':
      return 'pic'
      break;
    case '.gif':
      return 'pic'
      break;
    case '.xlsx':
      return 'xls'
      break;
    case '.zip':
      return 'zip'
      break;
    case '.rar':
      return 'zip'
      break;
    case '.mp3':
      return 'mp3'
      break;
    case '.wav':
      return 'mp3'
      break;
    case '.wma':
      return 'mp3'
      break;
    case '.plac':
      return 'mp3'
      break;
    case '.aac':
      return 'mp3'
      break;
    default:
      return 'unknown'
      break
  }
}

import ddfz from "@/assets/ddfz.png";
import yw from "@/assets/yw.png";
import sx from "@/assets/sx.png";
import kx from "@/assets/kx.png";
import eng from "@/assets/en.png";
import yy from "@/assets/yy.png";
import ty from "@/assets/ty.png";
import ms from "@/assets/ms.png";
import zhsj from "@/assets/zhsj.png";
import bd from "@/assets/bd.png";

export function getIconWithType(name) {
  switch (name) {
    case "语文":
      return yw;
      break;
    case "数学":
      return sx;
      break;
    case "英语":
      return eng;
      break;
    case "体育":
      return ty;
      break;
    case "音乐":
      return yy;
      break;
    case "美术":
      return ms;
      break;
    case "科学":
      return kx;
      break;
    case "综合实践":
      return zhsj;
      break;
    case "班队":
      return bd;
      break;
    case "道德与法治":
      return ddfz;
      break;
    default:
      return zhsj;
      break;
  }
}

/**
 * 根据url获取oss文件的source
 */
export function getSource(apxurl) {
  let domain = apxurl.split("/"); //以“/”进行分割
  if (domain[2]) {
    domain = domain[2];
  } else {
    return ''
  }
  let source = apxurl.replace('http://' + domain + '/', '')
  source = source.replace('https://' + domain + '/', '')
  return source
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}


export function animateCSS(element, animation, remove = true, prefix = 'animate__') {
  // We create a Promise and return it
  return new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);
    if (node) {
      node.classList.add(`${prefix}animated`, animationName);

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd() {
        console.log('handleAnimationEnd')
        if (remove) {
          node.classList.remove(`${prefix}animated`, animationName);
        }

        resolve('Animation ended');
      }

      // var animationEnd = 'animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend';

      node.addEventListener('animationend', handleAnimationEnd, { once: true });
    } else {
      reject('error')
    }
  });
}
