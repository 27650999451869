<template>
  <div class="website">
      <web-nav></web-nav>
      <div class="web-content">
          <router-view />
      </div>
      <web-footer></web-footer>
  </div>
</template>

<script>
import WebFooter from '../components/website/WebFooter.vue'
import WebNav from '../components/website/WebNav.vue'
export default {
    components: {
        WebFooter,
        WebNav
    }
}
</script>

<style lang="less">
.website {
    width: 100%;
    background: white;
}
.web-content {
    margin: 0 auto;
    overflow-x: hidden;
    min-height: 80vmin;
    background: #EDF0F5;
    max-width: 1920Px;
}
</style>