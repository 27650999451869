<template>
  <div class="footer">
    <div class="footer-content">
      <ul class="links">
          <li class="link-child-item">峰会直播</li>
          <li class="link-child-item">
            <router-link style="color:white;" to="map">课程博览</router-link>
          </li>
          <li class="link-child-item">
            <a style="color:white;" href="http://www.cqcdbs.com">集团网站</a>
          </li>
      </ul>
      <ul class="links">
          <li class="link-child-item">重庆市巴蜀小学校教育集团</li>
          <li class="link-child-item">地址：重庆市渝中区巴教村15号 </li>
          <li class="link-child-item">课程征订：023-63862526 15320381878</li>
          <li class="link-child-item">技术支持：重庆骏懋教育科技有限公司</li>
          <li class="link-child-item">技术服务：18002370016</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-footer"
}
</script>

<style lang="less">
.footer {
    background: #801212;
    min-height: 150Px;
    padding-bottom: 60Px;
    max-width: 1920Px;
    margin: 0 auto;
    .footer-content {
      width: 1170Px;
      min-height: 150Px;
      margin: 0 auto;
      .links {
        display: flex;
        padding: 0;
        padding-top: 50Px;
        justify-content: center;
        .link-child-item {
          padding: 4Px 0;
          color: white;
          padding: 0 10Px;
          cursor: pointer;
        }
      }
    }
}
</style>